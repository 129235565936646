//
// Bootstrap extended functions
//

@function theme-inverse-color($key: "primary") {
    @return get($theme-inverse-colors, $key);
}

@function theme-outline-inverse-color($key: "primary") {
    @return get($theme-outline-inverse-colors, $key);
}

@function theme-hover-color($key: "primary") {
    @return get($theme-hover-colors, $key);
}

@function theme-light-color($key: "primary") {
    @return get($theme-light-colors, $key);
}

@function theme-shadow-color($key: "primary") {
    @return get($theme-shadow-colors, $key);
}
