//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//


// Theme functions
@import
    "base/functions/get",
    "base/functions/math",
    "base/functions/valueif",
    "base/functions/theme-colors";

// Theme mixins
@import
    "base/mixins/attr",
    "base/mixins/browsers",
    "base/mixins/fixes",
    "base/mixins/reset",
    "base/mixins/placeholder",
    "components/mixins/tooltip",
    "components/mixins/buttons",
    "components/mixins/burger-icon",
    "components/mixins/checkbox",
    "components/mixins/radio",
    "components/mixins/switch",
    "components/mixins/ki",
    "components/mixins/symbol",
    "components/mixins/menu-horizontal",
    "components/mixins/menu-vertical",
    "components/mixins/offcanvas",
    "components/mixins/spinner",
    "components/mixins/svg-icon",
    "components/mixins/navi",
    "components/mixins/label",
    "vendors/plugins/mixins/perfect-scrollbar";

// Bootstrap mixins
// @import "../../../tools/node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap-scss/mixins";

// Global variables
@import "components/variables.demo";
@import "components/variables.bootstrap";
@import "components/variables.custom";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";

// Theme layout variables
@import "layout/variables";
